.user-list {
  margin-top: 50px;
}

.user-table {
	overflow-x: visible;
}

.profile-picture {
	position:absolute;
	bottom:0%;
}

.label-font {
	font-weight: normal;
}