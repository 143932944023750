.sidebarText a {
  color: #ebebeb !important;
  padding-right: 15px;
  padding-left: 15px;
}

.sidebarText a:hover {
  color: #999999;
}

.sidebarText a:-webkit-any-link {
  text-decoration: none;
}

.sidebarText.customScrollbar {
  padding: 10px 0;
  height: 450px;
  background-color: white;
  margin-bottom: 0;
  overflow: auto;
}
.sidebarText.customScrollbar > div {
  background-color: white;
  padding-left: 15px;
  padding-right: 15px;
}
.sidebarText a:hover {
  color: #999999;
}
.sidebarText a:-webkit-any-link {
  text-decoration: none;
}

@media (min-width: 768px) and (max-width: 1024px){
  $sidebarTextPadding: 0.5rem;
  
  .sidebarText a {
    color: #ebebeb;
    padding-right: #{$sidebarTextPadding};
    padding-left: #{$sidebarTextPadding};
  }
  .sidebarText.customScrollbar > div {
    background-color: white;
    padding-left: #{$sidebarTextPadding};
    padding-right: #{$sidebarTextPadding};
  }
}

/*FireFox only*/
@-moz-document url-prefix() {       
  .firefoxMenu {
    margin-bottom: 20px;
  }
  .firefoxSubmenu {
    margin-top: 0px !important;
  }
  .firefoxHr {
    display: inline-block;
    margin-top: 0px;
  }
}

.customScrollbar.panel-group {
  padding: 0 0;
  margin-bottom: 0;
  background-color: #2B2B2B; 
  float: left;
  max-height: 100%;
  color: #333;
  margin-right: 15px;
  position: fixed;
  overflow: auto;
  top: 50px;
  bottom: 0;
}

/*.customScrollbar::-webkit-scrollbar {
  width: 10px;
}
.customScrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}
.customScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}
.customScrollbar::-moz-scrollbar {
  width: 10px;
}
.customScrollbar::-moz-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}
.customScrollbar::-moz-scrollbar-thumb:hover {
  background-color: #aaa;
}
.customScrollbar::-ms-scrollbar {
  width: 10px;
}
.customScrollbar::-ms-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}
.customScrollbar::-ms-scrollbar-thumb:hover {
  background-color: #aaa;
}*/

// #wrapper section

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  // layering (for show/hide sidebar on mobile)
  position: relative;
  z-index: 1001;
}

#wrapper.toggled {
  padding-left: 250px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

@media(min-width:768px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #page-content-wrapper {
    padding: 20px;
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

// fix sidebar on mobile
#headernavdesktop-accordion {
  margin-bottom: 0; 
  padding: 0;
  background-color: #2B2B2B; 
  float: left; 
  max-height: 100%; 
  color: #333; 
  margin-right: 15px; 
  position: fixed; 
  overflow: auto; 
  top: 50px; 
  bottom: 0; 
  margin-left: -250px;
}

.show-sidebar {
  display: block !important;
  padding-left: 250px !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.hide-sidebar {
  padding-left: 0px !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.sidebar-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #0008;
  z-index: 199;
  display: none;
}

@media (min-width: 768px) {
  #headernavdesktop-accordion {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    width: 50%;
  }
  .show-sidebar {
     padding-left: 0px !important;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .sidebar-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #0008;
    z-index: 199;
    display: none !important;
  }
}