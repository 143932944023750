// routes
@import "role-manager/index";
@import "user/index";

// components
@import "components/header-main";
@import "components/page-navbar";
@import "components/wizard";

@font-face {
  font-family: myfonts;
  src: url('/fonts/OpenSans.ttf');
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

a:hover {
  text-decoration:none;
}

.bump {
  margin-top: 20px;
}

.modal .modal-body {
  max-height: 400px;
  overflow-y: auto;
}

.modal-header{
  border-bottom: 0px;
  background-color: #f9f9f9;
}

.modal-footer{
  border-top: 0px;
}

.loading-pane {
  margin: 20% auto;
}

.loading-pane-padding {
  padding: 25%;
}

.loading-message {
  text-align: center;
  color: #aaa;
  padding-bottom: 10px;
}

.spinner {
  background: url('/images/ajax-loader.gif') no-repeat;
  height: 31px;
  width: 31px;
  margin: 0 auto;
}

.menu-container {
  padding-bottom: 50px
}

.right-inner-addon {
  position: relative;
}

.right-inner-addon input {
  padding-right: 30px;    
}

.right-inner-addon i {
  position: absolute;
  right: 0px;
  padding: 10px 12px;
  pointer-events: none;
}

.lg .right-inner-addon i {
  position: absolute;
  right: 0px;
  padding: 10px 12px;
  pointer-events: none;
  margin-top: 23px;
  margin-right: 10px;
}

.navbar .divider-vertical {
  height: 50px;
  margin: 0 9px;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #f2f2f2;
}

.navbar-inverse .divider-vertical {
  border-right-color: #222222;
  border-left-color: #111111;
}

@media (max-width: 767px) {
.navbar-collapse .nav > .divider-vertical {
    display: none;
  }
}

.menu-app-list {
  padding: 10px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-app-list:hover {
  background-color: #CECCCC;
  transform: scale(1.2);
}

.auth-box {
  background-color: white;
  border: 1px solid #d4dfe7;
  border-radius: 4px;
}

.auth-form {
  padding: 40px 0;
}

.float-none {
  float: none;
}

.margin-0 {
  margin: 0px;
}

.margin-top-15-p {
  margin-top: 15%;
}

.margin-top-10-p {
  margin-top: 10%;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-23 {
  margin-top: 23px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-bot-5 {
  margin-bottom: 5px;
}

.margin-bot-10 {
  margin-bottom: 10px;
}

.margin-bot-20 {
  margin-bottom: 20px;
}

.margin-bot-40 {
  margin-bottom: 40px;
}

.margin-right--4 {
  margin-right: -4px;
}

.dropdown-icon-error {
  margin-right: 6px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-70 {
  margin-left: 70px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-24 {
  font-size: 24px;
}

.remove {
  color: #d9534f;
}

.remove:hover {
  color: #c9302c;
}

.red {
  color: #d9534f;
}

.white {
  color: white;
}

.grey {
  color: #777;
}

.border-radius-4 {
  border-radius: 4px;
}

.padding-0 {
  padding: 0px;
}

#role-list > li {
    display: inline-block;
    /* You can also add some margins here to make it look prettier */
    zoom:1;
    *display:inline;
    /* this fix is needed for IE7- */
}

// ul > li{
//     display: inline-block;
//     zoom:1;
//     display:inline; /* this fix is needed for IE7- */
// }

.assigned-icon {
  min-height: 20px;
  padding: 10px;
  margin-bottom: 20px;
}

.modal-icon:hover {
  border: 1px solid #606060;
  background-color: #F0F0F0;
}

.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  -ms-word-break: normal;
  word-break: normal;
}

.dropdown-limit {
  top: 2px;
  position: relative;
}

.break {
  word-break: break-all;
}

.table-responsive {   
  width: 100%;
  margin-bottom: 15px;
  overflow-x: auto;   
  overflow-y: hidden;     
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.fixed {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}

// hide spinner from number-input
input[type=number] {-moz-appearance: textfield;}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.signup-card-padding {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .no-glow-text {
    outline: none;
    box-shadow:none !important;
  }
  .shiki-fuin {
      background-image: url('sign up content-02.png');
      background-position: bottom;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 10px solid;
      border-color: green;
      border-left: none;
    background-size: cover;
  }
  .sign-up-border {
    border: 10px solid;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
      border-color: green;
  }
  .signup-card-padding {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .no-glow-text {
    outline: none;
    box-shadow:none !important;
  }
  .transparent-input {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .grey-input {
    background-color: rgba(242, 242, 242, 1) !important;
  }
  .clear::-ms-clear {  display: none; width : 0; height: 0; }
  .clear::-ms-reveal {  display: none; width : 0; height: 0; }
  .clear::-webkit-search-decoration,
  .clear::-webkit-search-cancel-button,
  .clear::-webkit-search-results-button,
  .clear::-webkit-search-results-decoration { display: none; }
  .card-shadow:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
  }

  .category-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;     /* fallback */
    height: 48px;      /* fallback */
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .cart-image {
    max-height: 100%;
    max-width: 100%;
  }

  .cart-image-div {
    height: 90px;
    widows: 90px;
  }

  .cart-item-title {
    font-size: 14px;
    margin-bottom: 0;
  }

  .cart-item-price {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
  }

  .cart-item-notes {
    font-size: 14px;
    margin-bottom: 0;
  }

  .cart-item-qty {
    text-align: center;
  }

  .cart-checkbox {
    width: 1.3em;
      height: 1.3em;
    background-color: white;
      border-radius: 50%;
      border: 1px solid #ddd;
      -webkit-appearance: none;
      outline: none;
      cursor: pointer;
  }

  .cart-checkbox:checked {
    background-color: green;
    font-family: 'FontAwesome';
    content: "\f00c";
  }

  .checkout-image {
    max-height: 100%;
    max-width: 100%;
  }

  .checkout-image-div {
    height: 90px;
    widows: 90px;
  }

  .checkout-item-title {
    font-size: 14px;
    margin-bottom: 0;
  }

  .checkout-item-price {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
  }

  .checkout-item-qty {
    font-size: 12px;
  }

  .btn-fix {
    padding: 0;
    border: none;
    white-space: normal;
    color: black;
    text-align: left;
  }

  .star {
      font-size: 20px;
      width: 20px;
      display: inline-block;
      color: gray;
  }
  .star:last-child {
      margin-right: 0;
  }
  .star:before {
      content:'\2605';
      height: 16px !important;
  }
  .star.on {
      color: orange;
  }
  .star.half:after {
      content:'\2605';
      color: orange;
      position: absolute;
      margin-left: -17px;
      width: 8px;
      overflow: hidden;
  }

  .bg-rating {
    background-color: orange !important;
  }

  .rcorners2 {
    border-radius: 30%;
    border: 1px solid lightgrey;
    padding: 1%;
    width: fit-content;
  }

  .category-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 16px;     /* fallback */
    height: 48px;      /* fallback */
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .footer-title {
    font-weight: bold;
  }

  .footer-link {
    font-size: 12px;
    color: grey;
  }

  .transaction-image {
    max-height: 100%;
    max-width: 100%;
  }

  .order-status-text {
    font-size: 12px;
  }

  .order-status-detail {
    word-break: break-word;
  }
