.roleMenuText a {
  color: #000;
  padding-right: 15px;
  padding-left: 15px;
}

.roleMenuText a:hover {
  color: #999999;
}

.roleMenuText a:-webkit-any-link {
  text-decoration: none;
}

.box {
  padding: 20px;
  border: 1px solid #ebebeb;
}

.box:hover {
  background-color: #f5f5f5;
}

.pointer {
  cursor: pointer;
}

.sub-box {
  border: 1px solid #f9f9f9;
}

.submenu {
  padding-bottom: 10px;
  background-color: #f9f9f9;
}

.span-glyphicon {
  float: right;
  cursor: pointer;
  padding-right: 15px;
}

.link-submenu {
  padding-top: 10px;
  padding-left: 20px;
}

.role-list {
  padding: 5px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select-role {
  margin-top: 10px;
  margin-bottom: 10px;
}

.content {
  padding-bottom: 9px;
  margin: 40px 0 20px;
}

.app-margin {
  margin: 0 5px;
}

.no-app {
  margin: 25% auto;
}

.add {
  margin:0;
  text-align: center;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

.module {
  margin-right: 0px;
  margin-left: 0px;
  position: relative;
}

#menu-toggle {
  float: left;
}

@media (min-width: 768px){
  #menu-toggle   {
      display: block;
  }
}

.role .glyphicon-plus:hover {
  color: #5cb85c;
}

.app-name {
  margin: 0 -15px;
}

.show-role {
  position: fixed;
  top:0%;
  left:27%;
  z-index: 9999
}

.icon {
  padding: 2px;
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: transparent;
}

.icon:hover {
  border-radius: 4px;
  border: 1px solid #606060;
  background-color: #F0F0F0;
}

.app-info {
  min-height: 60px;
  padding-top: 15px;
  margin-bottom: 20px;
}

.app-icon {
  height: 160px;
  width: 160px;
}

.delete-icon {
  position:absolute;
  right: 0%;
  top: 0%;
}

.edit-icon {
  position: absolute;
  right: 5%;
  top: 0%;
}

.create-btn {
  position:fixed;
  right: 4%;
  bottom: 5%;
  transform: translateX(-2.5%);
}
#assignUserModal, #assignGroupModal, #deleteModuleModal, #deletePageModal, #deleteActionModal {
  z-index: 99999
}

.modal-box {
  background-color: white;
  padding: 0 0;
  border-radius: 4px;
}

.modal-success {
  padding: 6px 12px
}

.hiddenRow {
    padding: 0 !important;
}