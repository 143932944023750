@import "bootstrap";
@import "dashboard/common";
@import 'scheduler/common';
@import "w3o-users-roles/common";
@import "w3office-forms/common";
@import 'w3oprocess/component/process-canvas';
@import 'w3oprocess/component/node-style';

@import "header-nav";

a:hover {
  text-decoration:none;
}
 
body {
	font-size: 14px;
	background-color :#f5f6f8;
	/*font-family: myfonts;
	font-family: sans-serif;*/
  font-family: 'Nunito Sans';
}

hr {
  border-color: #999999;
  /*border-color: #ebebeb;*/
}

input[type="text"] {
	height: 37px;
}

//hide default radio circle in variant button
input[type="radio"] {
    visibility:hidden;
}

select {
	height: 37px;
}

.custom-button {
	height: 37px;
}

//circle text
.circle {
    display: table-cell;
    height: 18px;
    width: 18px;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    background: #E8A892;
    color: white;
}

textarea {
  resize: none;
  box-sizing:border-box;
}

.ease-animate {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

label {
  font-weight: bold;
}

.cursor-pointer:hover {
  cursor: pointer !important;
}

//Standard Component: DatetimePicker
.datetime-picker {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group > .datetime-picker {
  flex: 1 1 auto;
  width: 1%;
}

.datetime-picker[disabled] {
  background-color: #e9ecef;
}

$breakpoint: 576px;

@media (max-width: #{$breakpoint}) {
    /* This block only applies to viewports <= #{$breakpoint} wide... */
    ::-webkit-scrollbar { 
        display: none; 
    }
    .mobile-box-shadow{
      box-shadow: 0 4px 6px 0 hsla(0,0%,0%,0.2);
      border-radius: 3%;
    }
    .mobile-container-center{
      -ms-flex-pack:center!important;
      justify-content:center!important
    }
    .icon {
      padding: 2px;
      border-radius: 4px;
      border: 1px solid transparent;
      background-color: transparent;
    }

    .icon:hover {
      border-radius: 4px;
      border: 1px solid transparent;
      background-color: transparent;
    }
    .brand-center-mobile {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

  /* not active */
.nav-pills .pill-1 .nav-link:not(.active) {
    background-color: white;
     color: #E8A892;
}

.nav-pills .pill-2 .nav-link:not(.active) {
    background-color:white;
    color: #E8A892;
}

.nav-pills .pill-3 .nav-link:not(.active) {
    background-color: white;
    color: #E8A892;
}


/* active (faded) */
.nav-pills .pill-1 .nav-link {
    background-color: #E8A892;
    color: white;
}

.nav-pills .pill-2 .nav-link {
    background-color: #E8A892;
     color: white;
}

.nav-pills .pill-3 .nav-link {
    background-color: #E8A892;
    color: white;
}

/* items */
.itemcard {
  width: 48%;
}

@media screen and (min-width: 768px) {
  .itemcard {
    width: 24%;
  }
}

@media screen and (max-width: 767px) {
  .profileaddress {
    white-space: nowrap;
  }
}

.container {
  min-height: 512px !important;
  min-height: 77vh !important;
}

.navbar-primary {
  background-color: white;
  color: black;
  box-shadow: -15px 0px 10px -15px #111;
}

.peachytext {
  color: #E8A892 !important;
}

.breadcrumbend {
  color: #E8A892 !important;
  font-weight: bold;
}

.peachybackground {
  background-color: #E8A892 !important;
  color: white !important;
}

.roundbutton {
  background-color: white;
  border-color: #333333;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.container-card {
  box-shadow: 5px 5px 15px #e2e2e2;
  background-color: white;
  border-radius: 1.25rem !important;
  border-top: 10px solid #E8A892 !important
}
.card {
  border-radius: 1rem !important;
  box-shadow: 5px 5px 15px #e2e2e2;
}

.card:hover, .card:focus, .card:active {
  border-color: #E8A892 !important;
}
.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active, .open>.dropdown-toggle.btn-outline-dark {
    background-color: #E8A892;
}

.cart-item-price {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-borderbottom {
  -webkit-appearance: none !important;
  border: none;
  border-bottom: 2px solid grey;
  border-radius: 10px;
  width: 100%;
}

.input-select {
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 10px;
  width: 100%;
  
}

.peachybackground-button {
  background-color: #E8A892 !important;
  color: white !important;
  width: 50%;
  border-radius: 2rem;

}

.image-style{
  width: 100%; 
  height: 75%; 
  border-radius: 5px;
  margin: 3rem 0;
  border-radius: 1.5rem;
}

.promo-image{
  width: 150px;
  height: 300px;
  border-radius: 1.25rem !important;
  border-color: #333333;
  box-shadow: 5px 5px 10px #e2e2e2;
}

.container-profile {
  box-shadow: 5px 5px 15px #e2e2e2;
  background-color: white;
  border-radius: 1rem !important;
}
