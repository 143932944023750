/*Base Component*/
.field-button-group {
  position: absolute;
  top: 10px;
  right: 12px;
}

.field-mark {
	position: absolute;
	right: 1px;
	bottom: 1px;
}

.componentContainer{
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0px 0px;
  padding: 10px 10px 10px 10px;
  height: 189px;
}

.simpleInputText{
  padding: 5px 5px 5px 5px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.12);
}

/*paragraph-text component*/
.simpleTextarea{
  margin: 0 0 10px 0px;
  padding: 5px 5px 5px 5px;
}

/*auto-complete, boolean-check, check-box, radio-button component*/
.simpleCheckboxGroup{
  /*margin: 0 0 10px 0px;
  padding: 5px 5px 5px 5px;*/
  overflow: auto;
}

.iconButton{
  display: inline-block;
  padding: 1px 4px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  float: right;
  background-color: #d4a58a;
}

/*boolean-check component*/
.boolean-padding {
  padding-left: 15px;
}

/*END OF Base Component*/
