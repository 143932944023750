@import "components/base-component";
@import "components/datetime-picker";
@import "components/form-upload";
@import "components/horizontal-slider";
@import "components/auto-complete";
@import "components/boolean-check";
/* * * * * * * * * * * * * * * * * * * * 
* To collapse all press sequentially:  *
* cmd/ctrl + k                         *
* cmd/ctrl + 1                         *
* * * * * * * * * * * * * * * * * * * */

/***  NEW BOOTSTRAP V4  ***/
.mobile-btn-ui-entries {
  width: 41px;
}

@media (max-width: 767.98px) {
  .full-screen-modal {
    .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      min-width: 100%;

      .modal-content {
        height: auto;
        min-height: 100%;
        border-radius: 0;
      }
    }
  }
}

/*** END OF  NEW BOOTSTRAP V4  ***/

.flash-messsage {  
  border-radius: 5px;
  padding: 8px 15px;
  margin-bottom:0px;
}

.flash-messsage.success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.unsuccessful {
  color: #CF3C3C;
  background-color: #E88B8B;
  border-color: #F7B0B0;
}

.builder-field-description {
  text-align: justify;
  text-justify: inter-word;
  color: #919191;
  font-size: 12px;
}

.builder-field-label.input-text {
  margin-top: 5px;
}

@font-face {
  font-family: myfonts;
  src: url('/fonts/OpenSans.ttf');
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

#mapfeed {
  height: 300px;
  width: 100%;

  .placeholder {
    width: 100%;
    height: 100%;
    background-image: url("/placeholders/greymap.png");
    opacity: 0.8;

    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
    -ms-filter: blur(1px);
    filter: blur(1px);
  }
}

.mce-path {/* CSS */
  display: none !important;
}

.mce-statusbar {
  border-width: 0 !important;
}

ul li{
  list-style-type: none;
}

@media (max-width: 767px) {
  .brand {
    text-align: left !important;
    font-size: 22px;
    padding-left: 20px;
    line-height: 50px !important;
  }
}

#toggle-button{
  background: transparent;
  top: 60%;

  /* CSS Transition */
  -webkit-transition: background .2s ease-in-out, border .2s ease-in-out;
  -moz-transition: background .2s ease-in-out, border .2s ease-in-out;
  -ms-transition: background .2s ease-in-out, border .2s ease-in-out;
  -o-transition: background .2s ease-in-out, border .2s ease-in-out;
  transition: background .2s ease-in-out, border .2s ease-in-out;

}

.hide{
  display: none;
}

.modal .modal-body {
  max-height: 400px;
  overflow-y: auto;
}

.modal-header{
  border-bottom: 0px;
  background-color: #f9f9f9;
}

.modal-footer{
  border-top: 0px;
}

.more .more-text{
  display: none;
}

.carousel-inner > .item > img {
  width: 100%;
  margin: auto;
}

.footer {
  color: black;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.dropdown-menu .nav > li > a {
  color: #333;
  padding-left: 5px;
  width: 140%;
}

.modal-margin {
  margin-top: 10px;
}
.component-sidebar {
  background-color: #FBFBFB;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 0;
  margin-left: 15px;
  right: 15px;
}
.component-navbar {
  padding: 0;
  background-color: white;
  margin-bottom: 0;
  padding-bottom: 15px;
}
.component-navbar > div {
  background-color: white;
  padding: 0 15px;
}
.pageheader {
  background-color: rgb(51, 122, 183);
  border-color: rgb(46, 109, 164);
  margin-top: 50px;
  margin-bottom: 20px;
  padding: 15px 15px;
  border-radius: 4px;
  z-index: 100;
}
.btn-pageheader {
  padding-left: 10px;
  border-color: white;
}
.btn-pageheader-xs {
  color: white;
  background-color: #333;
  height: 65px;
  display: flex;
  align-items: center;
}
.btn-pageheader-xs > span {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.btn-pageheader-xs > span > i {
  font-size: 5.5vw;
  margin-bottom: 5px;
}
.formcollapse.in {
  display: table-cell;
}
.hrSidebar {
  border-color: #474747;
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.drop-field {
  background-color: #999999;
  text-align: center;
  border-radius: 4px;
}

.fieldContent{
  border-width: 1px !important;
}

.borderDanger {
  padding-top: 15px;
  padding-bottom: 7px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  /*min-height: 189px;*/
}

.borderDefault {
  padding-top: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 189px;
}

.settingsNav > hr {
  margin: 0 0;
}

.btnSettings {
  width: 100%;
  color: #333;
  display: inline-block;
  padding: 0 15px;
}

.btnSettings > span {
  padding-top: 15px;
  padding-bottom: 15px;
}

.btnSettings:hover {
  background-color: #ddd;
}

.settings-border {
  padding: 15px 0;
  margin-bottom: 15px;
  border: 2px solid #ebebeb;
  border-radius: 4px;
}

.alert-default {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
}

.orderDrop{
  background-color: transparent;
  /*margin: 5px 5px 5px 5px;*/
  // margin: 10px 5px 10px 5px;
  // padding: 10px 10px 10px 10px;
}

.propertiesDropdown {
  background-color: #c3c1c0;
  margin: 10px 0px 0px 0px;
  padding: 10px 10px 10px 10px;
}

.propertyContent{
  background-color: #f4f2f0;
  padding: 10px 10px 10px 10px;
  margin: 0px 0px 10px 0px;
}

.grow {
  height: 20px;
  transition:height 0.5s;    
  transition:border-style 0.5s;
  -webkit-transition:height 0.5s; /* For Safari */
}

.requiredMessage {
  color: red;
}

.errorMessage{
  margin-left: 30px;
  background-color: #d9534f;
  border-color: transparent #d9534f;
  padding: 10px 5px 0px 5px;
  margin-bottom: 0;
}

.errorMessage:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 15px 15px 15px 0;
  border-color: transparent #d9534f;
  display: block;
  width: 0;
  z-index: 1;
  left: -15px;
  top: 5px;
}

.successMessage {
  margin-left: 30px;
  background-color: #5CB85C;
  border-color: transparent #5CB85C;
  padding: 10px 5px 0px 5px;
  margin-bottom: 0;
}

.successMessage:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 15px 15px 15px 0;
  border-color: transparent #5CB85C;
  display: block;
  width: 0;
  z-index: 1;
  left: -15px;
  top: 5px;
}

.loading-bar {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.loading-bar > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.loading-bar .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.loading-bar .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.loading-bar .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.loading-bar .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

[data-tip] {
  position:relative;
}

[data-tip]:after {
  display:none;
  content:attr(data-tip);
  position:absolute;
  top: -12px;
  left:35px;
  padding:5px 8px;
  background:#1F2837;
  color:#fff;
  z-index:1060;
  font-size: 0.75em;
  line-height:18px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  white-space:normal;
  text-align: left;
  width: 200px;
  word-wrap: break-word;
}

@-webkit-keyframes tip-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes tip-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@-o-keyframes tip-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes tip-in {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

[data-tip]:hover:after {
  -webkit-animation: tip-in 0.5s; /* Safari 4+ */
  -moz-animation:    tip-in 0.5s; /* Fx 5+ */
  -o-animation:      tip-in 0.5s; /* Opera 12+ */
  animation:         tip-in 0.5s; /* IE 10+, Fx 29+ */
  display:block;
}

.bn{
  background-color: #f0cdd1;
  padding: 5px 5px 5px 5px;
  border-radius: 3px;
  margin: 5px 5px 5px 5px !important;
  color: white;
  width: auto;
}

.bn .bn-component{
  
}
/*End of Addition*/


/**
 * Tooltip Styles
 */

/* Base styles for the element that has a tooltip */
[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: 
      opacity 0.2s ease-in-out,
        visibility 0.2s ease-in-out,
        -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    -moz-transition:    
        opacity 0.2s ease-in-out,
        visibility 0.2s ease-in-out,
        -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    transition:         
        opacity 0.2s ease-in-out,
        visibility 0.2s ease-in-out,
        transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform:    translate3d(0, 0, 0);
  transform:         translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  min-width: 160px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%;
}

[data-tooltip]:before,
.tooltip:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip-top:after {
  margin-left: -80px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform:    translateY(-12px);
  transform:         translateY(-12px); 
}

/* Left */
.tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}

.tooltip-left:before {
  margin-left: 0;
  margin-right: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-left-color: #000;
  border-left-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-left:hover:before,
.tooltip-left:hover:after,
.tooltip-left:focus:before,
.tooltip-left:focus:after {
  -webkit-transform: translateX(-12px);
  -moz-transform:    translateX(-12px);
  transform:         translateX(-12px); 
}

/* Bottom */
.tooltip-bottom:before,
.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  left: 50%;
}

.tooltip-bottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #000;
  border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after {
  -webkit-transform: translateY(12px);
  -moz-transform:    translateY(12px);
  transform:         translateY(12px); 
}

/* Right */
.tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%;
}

.tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform:    translateX(12px);
  transform:         translateX(12px); 
}

/* Move directional arrows down a bit for left/right tooltips */
.tooltip-left:before,
.tooltip-right:before {
  top: 3px;
}

/* Vertically center tooltip content for left/right tooltips */
.tooltip-left:after,
.tooltip-right:after {
  margin-left: 0;
  margin-bottom: -16px;
}

.disableTableResponsiveness {
  overflow-x: visible !important;
}

.borderRed {
  border-style: solid solid solid solid !important;
  border-color: red;
}

[error-popout] {
  position: relative;
  cursor: pointer; 
}

[error-popout]:after,
[error-popout]:before {
  //no hover
  position: relative;
  pointer-events: none;

  bottom: 100%;
  left: 50%;
  //hover
  visibility: visible;
  opacity: 1;
}
/*
[error-popout]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";

  top: 45px;
  border-top-color: #de1600;
}
*/
[error-popout]:after {
  z-index: 1000;
  padding: 8px;
  display: block;
  background-color: #de1600;
  color: #fff;
  content: attr(error-popout);
  font-size: 80%;
  line-height: 1.2;

  margin-left: -70px;
  top: 13px;
}

.grow-column {
  transition:width 0.5s;    
  transition:border-style 0.5s;
  -webkit-transition:width 0.5s; /* For Safari */ 
}

.grid-drop-col {
  position: absolute;
  float: left; 
  width: 15px;
  margin: 0px 0px;
  border-style: none;
  border-color: #5e5e5c;
  z-index: 100;
}

.grid-component {
  position: relative;
  float: left;
  width: 100%;
}

.drop-col {
  height: 189px;
  background-color: transparent;
}

.drop-col-xs {
  height: 20px;
  border-color: white;
  transition:height 0.5s;    
  transition:border-style 0.5s;
  -webkit-transition:height 0.5s; /* For Safari */
}

ul.listSelected, ul.listSelected li{
 display:inline-block;
}

span.dropdown-menu > div:hover{
  background-color:#1F2837;
  color:white;
}

.grid-row-spacing
{
  height: 20px;
}

.auto {
  opacity: 1 !important;
}

.box-wrapper {
  margin: 20px auto;
  background: white;
  padding: 10px;
  text-align: center;
    
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
  border-radius:10px;
    
 
  -webkit-box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
    
  box-shadow: 0px 0px 8px rgba(0,0,0,0.3);
  position: relative;
  z-index: 90;
}

.other-input {
  display: inline;
  width: 40%;
}

.table-input {
  display: inline;
  width: 70%;
}

.disabled-form {
  background-color: white !important;
  opacity: 1 !important;
  cursor: not-allowed !important;
}

.alert-sm{
  padding: 8px 15px 8px 15px;
  margin-bottom: 0px;
}

.alert-md{
  padding: 15px 15px 15px 15px;
  margin-bottom: 0px;
}


/* Table on Entries */
.table-striped-column > tbody > tr td:nth-of-type(odd) { 
  background-color: #f9f9f9;
}

.table-striped-column > thead > tr th { 
  background-color: #f1f1f1;
}

.table-striped-column > thead > tr > th > a, .table-striped-column > thead > tr > th > span { 
  color: #005570 !important;
}

.ellipsisTableColumn {
  max-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
/* End of Table on Entries */

#mega-map {
  height: 450px;
  margin: 25px 0px 25px 0px;
}

.word-wrap .fc-day-grid-event .fc-content{
  white-space: normal !important;
}

.boolean-positioning{
  float: left; 
  padding-left: 15px;
}

.custom-sm-pagination > tbody {
  float: right;
}

/* Children Tab - ui.entry.index */
.tabs-children > li {
  border-bottom: 2px solid #ddd;
}

.tabs-children > li > a {
  cursor: pointer;
}
/* End of Children Tab */

.input-group-addon {
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #ccc;
}

/* Entries Advanced Filter Component*/
.min-width-227 {
  margin-left: 0;
  margin-right: 0;
  min-width: 227px;
}
/* End of Entries Advanced Filter Component*/
